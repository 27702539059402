<template>
  <div class="containers">
      <div class="companyName">
        {{company_name}}：
      </div>
    <div class="textBox selfStyle">
      <div class="flex textIndent">
        本人
        <van-field class="inputs input50" v-model="query.name" />
        (身份证号： <van-field class="inputs input130" v-model="query.ID_number" />
      </div>
      <div class="flex">
        电话号码： <van-field class="inputs input80" v-model="query.phone" />)
        已于原单位
        <van-field class="inputs input50" v-model="query.leave_year" />
        年
        <van-field class="inputs input50" v-model="query.leave_month" />
        月
        <van-field class="inputs input50" v-model="query.leave_day" />
        日解除劳动关系，并办理全部离职手续，
        将于
        <van-field class="inputs input50" v-model="query.create_year" />
        年
        <van-field class="inputs input50" v-model="query.create_month" />
        月
        <van-field class="inputs input50" v-model="query.create_day" />
        日与{{company_name}}(以下简称
        <div>
          “公司”)建立劳动关系，但截至本人前往贵司办理入职手续之日因
        </div>
        <van-field style="width: 100%" class="inputs" v-model="query.reason" />
        原因本人暂时无法提供原用人单位《离职证明》，特向公司承诺如下：
        <div class="textIndent">
          一、本人承诺，在贵司签订劳动合同时，本人与原用人单位之间不存在劳动关系，并且不存在任何尚未解决的劳动法律纠纷及或其他可能影响本人在公司正常工作的利益纠纷。
        </div>
        <div class="textIndent">
          二、本人承诺与原用人单位之间不存在尚处于有效期内的可能影响本人在公司正常工作的保密协议、竞业协议或其他相关条款。
        </div>
        <div class="textIndent">
          若上述承诺不属实，导致公司与任何第三方产生任何纠纷的，本人承诺本人将独立解决前述纠纷及承担全部责任，贵司有权解除与本人的劳动合同，且不支付经济补偿金。若公司因此遭受任何损失，本人承诺全额赔偿。
        </div>
      </div>
    </div>
    <div style="margin-top: 30px;">
      <div class="sign">
        <div class="signLabel">入职人签字:</div>
        <vue-esign v-if="!query.sign_file" id="sign"  class="card" ref="esign" :height="350" :isCrop="false" :lineWidth="10" :lineColor="'#000'" />
        <img v-else class="card" :src="query.sign_file" alt="">
      </div>
      <div style="display: flex;justify-content: flex-end">
        <div class="reset" @click="resetSign">重新签名</div>
      </div>
    </div>

    <div style="margin: 20px 0"></div>
    <div style="display: flex;align-items: center">
      <span class="signLabel">日期:</span>
      <div class="selfStyle" style="display: flex; align-items: center;margin-left: 22px;">
        <van-field class="inputs input50" v-model="query.select_year"/>
        年
        <van-field class="inputs input50" v-model="query.select_month" />
        月
        <van-field class="inputs input50" v-model="query.select_day" />
        日
      </div>
    </div>
    <div class="submitBtn" @click="submitInfo">保存</div>

  </div>
</template>

<script>
import axios from "axios";
import {SaveCertificate, LoadCertificate, uploadFile} from "@/api/paper";

export default {
  data() {
    return {
      query: {},
      company_id: '',
      company_name: ''
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      LoadCertificate({openId: this.$store.state.info.openId}).then(res => {
        console.log(res);
        this.company_name = res.row.company_name
        this.company_id = res.row.company_id
        this.query = res.row
      })
    },
    submitInfo() {
      if(!this.query.name) {
        return  this.$notify({
          type: "warning",
          message: "请输入姓名",
        });
      }
      if(!this.query.ID_number) {
        return  this.$notify({
          type: "warning",
          message: "请输入身份证号",
        });
      }
      if(!this.query.leave_year || !this.query.leave_month || ! this.query.leave_day) {
        return  this.$notify({
          type: "warning",
          message: "请将离职日期填写完整",
        });
      }
      if(!this.query.create_year || !this.query.create_month || ! this.query.create_day) {
        return  this.$notify({
          type: "warning",
          message: "请将日期填写完整",
        });
      }
      if(!this.query.reason) {
        return  this.$notify({
          type: "warning",
          message: "请输入原因",
        });
      }
      if(!this.query.sign_file && !this.$refs.esign.hasDrew ) {
        return this.$notify({
          type: "warning",
          message: "请签名~",
        });
      }
      if(!this.query.select_year || !this.query.select_month || ! this.query.select_day) {
        return  this.$notify({
          type: "warning",
          message: "请将日期填写完整",
        });
      }
      if(this.query.sign_file) {
        this.submit()
      } else {
        this.drawImg()
      }
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    async drawImg() {
      this.$refs.esign.generate().then(res => {
        var blob = this.dataURLtoBlob(res)
        var tofile = this.blobToFile(blob, '签名.png')
        setTimeout(async () => {
          const formData = new FormData()
          formData.append('file', tofile, tofile.name)
          // ajax 请求
          uploadFile(formData).then(res => {
            this.query.sign_file = res.filePath
            this.query.sign_pic_name = res.picName
            this.submit()
          })
        })
      })
    },
    submit() {
      this.query.openId = this.$store.state.info.openId
      SaveCertificate(this.query).then(res => {
        console.log(res);
        if(res.result) {
          this.$notify({
            type: "success",
            message: "提交成功",
          });
          this.$store.dispatch('SaveLeaveImg', res.filePath)
          console.log(this.$store.state);
          setTimeout(() => {
            this.$router.go(-1)
          },1500)
        }
      })
    },
    resetSign() {
      if(this.query.sign_file) {
        this.query.sign_file = ''
      } else {
        this.$refs.esign.reset()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .containers {
    box-sizing: border-box;
    padding: 33px 18px;
  }
  .companyName {
    font-family: PingFang SC;
    font-size: 15px;
    color: #666666;
    line-height: 10px;
    margin-bottom: 10px;
  }
  .textBox {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 11px;
    color: #777777;
    line-height: 16px;
  }
  .textIndent {
    text-indent: 2em;
  }
  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-all;
  }
  .inputs {
    font-size: 12px;
    border-bottom: 1px solid #DDDDDD;
    text-align: center !important;
    padding: 0 !important;
  }
  .input50 {
    width: 50px;
  }
  .input130 {
    width: 130px;
  }
  .input80 {
    width: 80px;
  }
  .sign {
    .signLabel {
      width: 80px;
      text-align: right;
      flex-shrink: 0;
      font-family: PingFang SC;
      font-size: 15px;
      color: #666666;
      margin-bottom: 20px;
    }
    .card {
      width: 100%;
      height: 70px;
      background: #F7F8FA;
      border: 1px solid #DDDDDD;
    }
  }
  .date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .submitBtn {
    background: #02DBCA;
    border-radius: 20px;
    color: #FFFFFF;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .reset {
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #02DBCA;
    color: #02DBCA;
    border-radius: 10px;
    margin-top: 15px;
  }
</style>
<style>
.selfStyle .van-field__control {
  text-align: center !important;
}
</style>
